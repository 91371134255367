<template>
  <div>
    <h3>{{ $t("configurator.code") }}</h3>
    <div v-highlight>
      <v-btn
        class="float-right"
        style="position:absolute;right:13px;"
        min-width="0"
        text
        @click="toClipboard(htmlout)"
        ><v-icon>mdi-content-copy</v-icon>
      </v-btn>
      <pre class="language-html"><code></code></pre>
    </div>
  </div>
</template>

<script>
import { component as VueCodeHighlight } from "vue-code-highlight";

export default {
  components: {
    VueCodeHighlight,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      url: "https://finsupport.de/plugin",
      htmlout: "",
    };
  },
  computed: {
    form: {
      get() {
        return this.value;
      },
      set(form) {
        this.$emit("input", form);
      },
    },
  },

  watch: {
    form: {
      handler() {
        this.htmlout = this.html();
        var code = document.getElementsByTagName("code")[0];
        code.textContent = this.htmlout;
        Prism.highlightElement(code);
      },
      deep: true,
    },
  },
  mounted() {
    this.htmlout = this.html();
    var code = document.getElementsByTagName("code")[0];
    code.textContent = this.htmlout;
    Prism.highlightElement(code);
  },
  methods: {
    toClipboard(txt) {
      navigator.clipboard.writeText(txt);
    },
    html() {
      var html = '<meta charset="UTF-8">\n';
      html +=
        '<meta name="viewport" content="width=device-width, initial-scale=1">\n\n';
      html +=
        "<!-- support plugin CSS -->\n" +
        "<link rel='stylesheet' href='" +
        this.url +
        "/style.css?v=1.1'></link>\n" +
        "\n<!-- custom styles for support plugin -->\n" +
        "<style>\n";
      html +=
        ".finsupport .fab, .finsupport .chat, .finsupport .help-button {\n" +
        "  background: -webkit-linear-gradient(-25deg, " +
        this.form.bg1 +
        " 0%, " +
        this.form.bg2 +
        " 100%);\n" +
        "  color: " +
        this.form.text +
        ";\n" +
        "}\n" +
        ".finsupport .help-button {\n" +
        "  box-shadow: 0 0 0 0 " +
        this.form.shadow +
        ";\n" +
        "}\n";
      if (this.form.position == "left") {
        html +=
          ".finsupport .fabs {\n" +
          "  right: unset;\n" +
          "  left: 0;\n" +
          "}\n" +
          ".finsupport .chat {\n" +
          "  right: unset;\n" +
          "  left: 85px;\n" +
          "}\n";
      } else if (this.form.position == "center") {
        html +=
          "@media only screen and (min-width: 480px) {\n" +
          ".finsupport .fabs {\n" +
          "  right: unset;\n" +
          "  left: 50vw;\n" +
          "}\n" +
          ".finsupport .chat {\n" +
          "  right: unset;\n" +
          "  left: calc(50vw + 85px);\n" +
          "}\n}\n";
      }
      html +=
        "</style>\n" +
        "\n<!-- support plugin JS -->\n" +
        "<script src='\/assets\/js\/jquery-3.6.0.min.js'><\/script>\n" +
        "<script src='" +
        this.url +
        "/main.js?locale=de&v=1.1'><\/script>\n";
      return html;
    },
  },
};
</script>

<style src="vue-code-highlight/themes/prism.css"></style>
<style>
.theme--light.v-application code {
  background: none;
}
.language-html .tag:first-of-type {
  margin-left: -5px;
}
</style>
