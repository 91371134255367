<template>
  <v-container id="configurator" fluid tag="section">
    <base-panel
      icon="mdi-application-cog"
      :title="$t('configurator.page title')"
    >
      <v-row>
        <v-col cols="12" md="4">
          <configurator-form ref="editForm" v-model="form"></configurator-form>
        </v-col>
        <v-col cols="12" md="8">
          <configurator-preview v-model="form"></configurator-preview>
          <configurator-code v-model="form"></configurator-code>
        </v-col>
      </v-row>
    </base-panel>
  </v-container>
</template>

<script>
import ConfiguratorForm from "./ConfiguratorForm";
import ConfiguratorPreview from "./ConfiguratorPreview";
import ConfiguratorCode from "./ConfiguratorCode";

export default {
  components: {
    ConfiguratorForm,
    ConfiguratorPreview,
    ConfiguratorCode,
  },
  data() {
    return {
      form: {
        bg1: "#1976D2",
        bg2: "#2986C2",
        text: "#ECEFF1",
        shadow: "#1976D2AA",
        position: "right",
      },
    };
  },
};
</script>
