<template>
  <v-form ref="form" @submit.prevent="preview">
    <v-select
      v-model="form.position"
      :items="positions"
      item-text="text"
      item-value="id"
      :label="$t('configurator.fields.position')"
    >
    </v-select>

    <base-color-picker
      :label="$t('configurator.fields.bg1')"
      v-model="form.bg1"
    ></base-color-picker>

    <base-color-picker
      :label="$t('configurator.fields.bg2')"
      v-model="form.bg2"
    ></base-color-picker>

    <base-color-picker
      :label="$t('configurator.fields.text')"
      v-model="form.text"
    ></base-color-picker>

    <base-color-picker
      :label="$t('configurator.fields.shadow')"
      v-model="form.shadow"
    ></base-color-picker>
  </v-form>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      menu_color: false,
      menu_accent: false,
      positions: [
        {
          id: "left",
          text: this.$t("configurator.position.left"),
        },
        {
          id: "center",
          text: this.$t("configurator.position.center"),
        },
        {
          id: "right",
          text: this.$t("configurator.position.right"),
        },
      ],
    };
  },

  computed: {
    form: {
      get() {
        return this.value;
      },
      set(form) {
        this.$emit("input", form);
      },
    },
  },

  methods: {
    preview() {
      return false;
    },
  },
};
</script>
