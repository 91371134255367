<template>
  <div class="finsupport">
    <div class="fabs" :style="fabsStyle">
      <div ref="chat" class="chat" :style="chatStyle" v-show="toggle">
        <div class="chat_header">
          <span id="chat_head">{{ $t("configurator.preview") }}</span>
          <div class="chat_loader"></div>
        </div>
        <div class="mail-input" v-show="toggle == 1">
          <div class="chat_login">
            Bitte Email-Adresse eingeben<input
              id="chat_log_email"
              placeholder="Email"
            /><a id="chat_send_email" class="fab is-visible" :style="iconStyle"
              ><div class="mdi mdi-email"></div
            ></a>
            <div class="chat_login_alert"></div>
          </div>
        </div>

        <div class="request" v-show="toggle == 2">
          <div id="chat_converse" class="chat_head" style="margin-left: 0.6rem">
            <p class="tell-us">Erzählen Sie uns mehr über Ihr Problem</p>
          </div>
          <div class="fab_field">
            <a id="fab_send" class="fab is-visible" :style="iconStyle">
              <div class="mdi mdi-send"></div>
            </a>
            <textarea
              id="chatSend"
              rows="2"
              name="chat_message"
              placeholder="Nachricht schreiben"
              class="chat_field chat_message"
            ></textarea>
          </div>
        </div>
      </div>
      <br />
      <button
        ref="helpButton"
        class="help-button pulse-button"
        :style="buttonStyle"
        id="prime"
        @click="toggleChat()"
      >
        <span>?</span> Hilfe
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      toggle: 0,
    };
  },
  computed: {
    form: {
      get() {
        return this.value;
      },
      set(form) {
        this.$emit("input", form);
      },
    },
    fabsStyle() {
      if (this.form.position == "center") {
        return "right:unset;left:50vw";
      }
      if (this.form.position == "left") {
        return "right:unset;left:0px";
      }
      return "";
    },
    iconStyle() {
      return (
        "background: -webkit-linear-gradient(-25deg, " +
        this.form.bg1 +
        " 0%, " +
        this.form.bg2 +
        " 100%); color: " +
        this.form.text +
        ";"
      );
    },
    buttonStyle() {
      return (
        "background: -webkit-linear-gradient(-25deg, " +
        this.form.bg1 +
        " 0%, " +
        this.form.bg2 +
        " 100%); color: " +
        this.form.text +
        ";box-shadow: 0 0 0 0 " +
        this.form.shadow +
        ";}"
      );
    },
    chatStyle() {
      return (
        "background: -webkit-linear-gradient(-25deg, " +
        this.form.bg1 +
        " 0%, " +
        this.form.bg2 +
        " 100%); color: " +
        this.form.text +
        ";}" +
        this.chatPosition
      );
    },
    chatPosition() {
      if (this.form.position == "center") {
        return "right:unset;left:calc(50vw + 85px)";
      }
      if (this.form.position == "left") {
        return "right:unset;left:85px";
      }
      return "";
    },
  },

  methods: {
    toggleChat() {
      this.toggle++;
      if (this.toggle == 3) this.toggle = 0;
    },
  },
};
</script>

<style scoped src="@/../public/plugin/style.css"></style>

<style scoped>
.finsupport .chat,
.finsupport .fab {
  display: block;
}
.finsupport .mdi::before {
  display: none;
}
</style>
